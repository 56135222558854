.cookie-banner {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  background-color: #ffffff !important; /* Branco */
  color: #000000 !important; /* Preto */
  text-align: center !important;
  padding: 15px !important;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1) !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  z-index: 1000 !important;
  border-top: 2px solid #be376a !important; /* Linha no topo */
}

.cookie-button.accept {
  background-color: #28a745 !important; /* Verde */
  color: white !important;
}

.cookie-button.decline {
  background-color: #dc3545 !important; /* Vermelho */
  color: white !important;
}

.cookie-banner p {
  flex: 1;
  margin: 0;
  font-size: 14px;
}

.cookie-banner a {
  color: #be376a; /* Cor do Lup */
  font-weight: bold;
  text-decoration: none;
}

.cookie-banner a:hover {
  text-decoration: underline;
}

.cookie-buttons {
  display: flex;
  gap: 10px;
}

.cookie-button {
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

